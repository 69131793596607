import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import WOW from "wowjs";

class AboutComponent extends Component {
    componentDidMount() {
        new WOW.WOW().init();
        window.scrollTo(0, 0)
      }
    render() {
        return (
            <div>
                <div className="about_compo">
                    <div className="container">
                        <h1 className="wow fadeIn" data-wow-delay="0.2s">Who we are</h1>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="content">
                                    <p className="wow zoomIn" data-wow-delay="0.5s">Adventure 24/7 Marketing Agency and Media Production House is a Tanzanian
                                        firm incorporated in November 2018. Its primary line of business ranges from
                                        Consultancy in PR, Branding, Experiential Marketing &amp; Events to Content
                                        Production. The company provides diligent creative idea and most effective
                                        business solutions for small medium and large enterprises with competitive
                                        prices. On the other hands, the company owns among others, A Television station
                                        namely Hun Tv that produces media content geared at propelling community
                                        development and society empowerment by preparing special educative
                                        programs tailor-made for specific audience to meet the specific intended
                                        objective in line with objective number five (5) of seventeen (17) Sustainable
                                        Development Goals (SDGs).</p>
                                    <div className="go_about wow bounceIn">
                                        <Link to="/about/"  className="go_about_page w3-btn">explore more</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AboutComponent
