import React, { Component } from 'react'

class Wemake extends Component {
    render() {
        return (
            <div>
                <div className="wemake_compo">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="content">
                                    <h1>“w e	ma k e	ideas	w or k ”</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Wemake
