import React, { Component } from 'react'
import Wemake from '../components/Wemake'
import VisionMission from '../components/VisionMission'
import Values from '../components/Values'

class About extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render() {
        return (
            <div>
                <div className="about_page">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="content">
                                    <h1>About us</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="about_section_one">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="content">
                                    <p><span>Adventure 24/7 Marketing Agency and Media Production House</span> is a Tanzanian firm incorporated in November 2018. Its primary line of business ranges from Consultancy in PR, Branding, Experiential Marketing & Events to Content Production. The company provides diligent creative idea and most effective business solutions for small, medium and large enterprises with competitive prices. On the other hand, the company produces media content geared at propelling community development and society empowerment by preparing special educative programs tailor-made for specific audience to meet the specifi­c intended objective.</p>
                                    <p>Furthermore, the company is a marketing company providing end to end marketing solution that ranges from Market Intelligence to brands, Exhibitions, Merchandising, Activations, Sales Promotions, Market Seeding, Innovation and Creative branding. The company has worked with companies to build and share their brand story  and authentically connect with their customers. Furthermore, we have helped our clients to create a structured approach to producing web content, define and implement message strategies, and craft web and mobile content. All that has been possible due to involvement of skilled, knowledgeable, experienced and efficient staff working as a team to handle their marketing strategy.</p>
                                    <p>Drawing from our experience in field of sales and corporate communications, we understand that it takes strategic thinking, good storytelling, and creative copywriting to create marketing strategies that deliver results. Adventure 24/7 Media Group has experience in applying these strategies within organizations of all sizes, including large corporations and small start-ups. The company is focusing exclusively in high quality and cost-effective innovation, creation, development and implementation of services.</p>
                                    <p>The company is staffed with skilled labour with expertise on traditional and new emerging digital media.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Wemake/>
                <VisionMission/>
                <Values/>
            </div>
        )
    }
}

export default About
