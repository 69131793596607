import React, { Component } from 'react'
import puma from '../images/puma.png';
import tahah from '../images/tawah.png';
import abantub from '../images/abantub.png';
import stara from '../images/stara.png';
import tancda from '../images/tancda.png';
import hugodomingo from '../images/hugodomingo.png';
import vodacom from '../images/vodacom.png';
import sautizabusara from '../images/sautizabusara.png';
import baroda from '../images/baroda.png';
import shantagold from '../images/shantagold.png';
import WOW from "wowjs";
class Partners extends Component {
    componentDidMount() {
        new WOW.WOW().init();
        window.scrollTo(0, 0)
    }
    constructor(props) {
        super(props)

        this.state = {
            items: [
                {
                    id: 1,
                    image: vodacom,
                    link: 'https://vodacom.co.tz'
                },
                {
                    id: 2,
                    image: puma,
                    link: 'https://pumaenergy.com/en/'
                },
                {
                    id: 3,
                    image: tancda,
                    link:'https://www.tancda.or.tz/'
                },
                {
                    id: 4,
                    image: tahah,
                    link: 'https://tawahtanzania.or.tz/'
                },
                {
                    id: 5,
                    image: stara,
                    link: 'https://instagram.com/staratanzania'
                },
                {
                    id: 6,
                    image: hugodomingo,
                    link: 'https://www.instagram.com/hugodomingoevents/?hl=en'
                },

                {
                    id: 7,
                    image: abantub,
                    link: ''
                },
                {
                    id: 8,
                    image: sautizabusara,
                    link: 'https://www.busaramusic.org/'
                },
                {
                    id: 9,
                    image: baroda,
                    link: 'https://www.bankofbaroda.co.tz/'
                },
                {
                    id: 10,
                    image: shantagold,
                    link: 'https://shantagold.com/'
                },



                ]
        }
    }
    render() {
        return (
            <div>
                <div className="partner_component" >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="content w3-card" >
                                <h1>Partners</h1>
                                    <p>
                                        As media marketing consultancy firm, we work with and have worked for,
                                        development agencies, corporates and individuals.
                                    </p>
                                    <div className="logos-section text-center">

                                            {(this.state.items || [])?.map((item, index) => {
                                           return <a className="wow bounceIn" data-wow-delay={(0.2 + index/10)+'s'} href={item?.link} target={'_blank'} key={item?.id}>
                                                <img src={item?.image} width="120px" height="90px" alt="" />
                                            </a>
                                            }
                                            )}



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Partners
