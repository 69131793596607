import React, { Component } from 'react'
import WOW from "wowjs";

export class Values extends Component {
    componentDidMount() {
        new WOW.WOW().init();
        window.scrollTo(0, 0)
      }
    render() {
        return (
            <div>
                <div className="value">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-7">
                                <div className="content1">
                                    <h1>Our values</h1>
                                    <p>We believe in Respect for self, Respect for our partner organizations & Respect for Society and its diverse cultures. We also value our PIE; Passionate, Innovative and Excellence in our work.</p>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="content2 wow flipInX" data-wow-delay="0.2s">
                                    <h1>Our keys for development</h1>
                                    <ul>
                                        <li>Desire for Excellence</li>
                                        <li>Trust and confidence build-up</li>
                                        <li>Innovation and creativity</li>
                                        <li>Transparency</li>
                                        <li>Teamwork</li>
                                    </ul>
                                    <hr/>
                                    <h1>We believe in</h1>
                                    <ul>
                                        <li>Motivation</li>
                                        <li>Collective responsibility and leadership</li>
                                        <li>Professionalism and ethics</li>
                                        <li>Adding values to our client needs</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Values
