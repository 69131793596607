import React, { Component } from 'react'
import WOW from "wowjs";

class VisionMission extends Component {
    componentDidMount() {
        new WOW.WOW().init();
        window.scrollTo(0, 0)
      }
    render() {
        return (
            <div>
                <div className="vision_mission">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="content w3-card wow zoomIn" data-wow-delay="0.2s">
                                    <h1>Mission</h1>
                                    <p>To partner with organizations so as to facilitate achievement of their goals by providing innovative and creative communication and marketing approaches with reliable, high quality, standard and reliable solution.</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="content w3-card wow zoomIn" data-wow-delay="0.2s">
                                    <h1>Vision</h1>
                                    <p>To be the reference point for efficient and cost effective media related productions and marketing solutions, in the field of development communication so as to achieve 100% customer satisfaction by delivering quality products and services at an afford- able cost</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default VisionMission
