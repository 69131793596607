import React, { Component } from 'react'
import HomeSection1 from '../components/HomeSection1'
import HomeSection2 from '../components/HomeSection2'
import AboutComponent from '../components/AboutComponent'
import Partners from '../components/Partners'
import ServiceComponent from '../components/ServiceComponent'
import Strategy from '../components/Strategy'
import WOW from "wowjs";

class Home extends Component {
    componentDidMount() {
        new WOW.WOW().init();
        window.scrollTo(0, 0)
      }
    render() {
        return (
            <div>
                <div className="home_pae">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="content">
                                    {/* <h1 className="wow zoomIn" data-wow-delay="0.5s">adventure 24/7</h1> */}
                                    <h2 className="wow zoomIn" data-wow-delay="0.9s"><q>we make ideas work</q></h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <HomeSection1/>
                <AboutComponent/>
                <ServiceComponent/>
                <HomeSection2/>
                <Strategy/>
                <Partners/>
            </div>
        )
    }
}

export default Home
