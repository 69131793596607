import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class Error extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render() {
        return (
            <div>
                <div className="error_page">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="content w3-card">
                                    <h1>4<span>0</span>4</h1>
                                    <h2>Oooooops!. The page you are looking for is not available.</h2>
                                    <div className="go_home">
                                        <Link to="/" className="home">Go Home</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Error
