import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class Contact extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render() {
        return (
            <div>
                <div className="contact_page">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="content">
                                    <h1>Reach us out</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contact_section_one">
                    <div className="container">
                        <h1>Contact us</h1>
                        <div className="row my-4">
                            <div className="col-md-4">
                                <a href="https://web.facebook.com/Adventure247-Tv-103182051279196/" target="blank">
                                    <div className="content w3-card">
                                        <i className="fa fa-facebook"></i>
                                        <h2>adventure247 tv </h2>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-4">
                                <a href="https://www.instagram.com/adventure247tv/" target="blank">
                                    <div className="content w3-card">
                                        <i className="fa fa-instagram"></i>
                                        <h2>adventure247tv </h2>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-4">
                                <a href="https://www.youtube.com/channel/UCyKswVhPNMwhHRtr7t5_xAg">
                                    <div className="content w3-card">
                                        <i className="fa fa-youtube"></i>
                                        <h2>adventure247 media</h2>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className="row my-4">
                            <div className="col-md-4">
                                <a href="tel:+255719247247">
                                    <div className="content w3-card">
                                        <i className="fa fa-phone"></i>
                                        <h2>+255 719 247 247</h2>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-4">
                                <Link to="/" className="website">
                                    <div className="content w3-card">
                                        <i className="fa fa-globe"></i>
                                        <h2>adventure247.co.tz</h2>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-4">
                                <div className="content w3-card">
                                    <i className="fa fa-map-marker"></i>
                                    <h2>Mikocheni A, House no 88 Dar es salaam, Tanzania</h2>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <div className="google_map">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="content">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3962.1162156206515!2d39.23376411530152!3d-6.755679795116559!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x185c4fc998eb18f9%3A0xc66ec640b146bdf1!2sAdventure247%20Media%20Headquarter!5e0!3m2!1sen!2stz!4v1589427206489!5m2!1sen!2stz" style={{width:"100%", height:"700px",border:0}} frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0" title="map"></iframe>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default Contact
