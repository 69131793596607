import React, { Component } from 'react'
import strategy from '../images/strategy.PNG'
import bg1 from '../images/bg1.jpg'
import WOW from "wowjs";

class Strategy extends Component {
    componentDidMount() {
        new WOW.WOW().init();
        window.scrollTo(0, 0)
      }
    render() {
        return (
            <div>
                
                <div className="strategy_compo_one">
                    <div className="container">
                        <div className="row no-gutters">
                                <div className="col-md-12">
                                    <div className="encloser w3-card wow zoomIn" data-wow-delay="0.1">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="content_1 wow zoomIn" data-wow-delay="0.4s">
                                                    <h1>Our strategic tool</h1>
                                                    <p>After conducting wide range of analysis to understand current and future media and marketing business needs.</p>
                                                    <p>As such, this adventure 24/7 strategic plan identifies a number of strategies that response to these, challenges and opportunities. It also identified strategic focus area with strategic objective</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="content_2 wow zoomIn" data-wow-delay="0.4s">
                                                    <img src={strategy} alt="strategic_im" className="img-fluid"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>

                <div className="strategy_compo">
                    <div className="container">
                        <div className="row no-gutters my-4">
                            <div className="col-md-5">
                                <div className="content_3 wow zoomIn" data-wow-delay="0.2s">
                                    <img src={bg1} alt="strategic_im" className="img-fluid"/>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="content_4 wow slideInRight" data-wow-delay="0.2s">
                                    <h1>Scope of operations</h1>
                                    <p>Adventure 24/7 Media Group, conducts Innovative, Creative and Quality Media productions in Various aspects of television feature films, documentary making, event coverage, informatics development, Radio productions and advertisements, Publications and written text for distribution across media channels.</p>
                                    <p>We also support our clients' organization's media relations efforts, after thye media productions. We also conduct Promotional activities for fast moving consumer goods, using a professional team, Activations, events, Sales Promotions and Market Seeding.</p>
                                    <p>We have a creative team that design merchandise with the help of our PR department that is concerned with the public image of existing partners and clients.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default Strategy
