import React, { Component } from 'react'
import { Link, NavLink } from 'react-router-dom'
import logo from '../images/alogo.png'

class Header extends Component {
    render() {
        return (
            <div className="header_component">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="content">
                                <nav className="navbar navbar-expand-lg navigation">
                                    <Link className="navbar-brand" to="/"><img src={logo} alt="logo" className="img-fluid"/></Link>
                                    <button className="navbar-toggler cpBtn" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </button>

                                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                        <ul className="navbar-nav mr-auto">
                                            <li className="nav-item">
                                                <NavLink exact={true} activeClassName='is-active' className="nav-link" to="/">Home</NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink activeClassName='is-active' className="nav-link" to="/about/">About us</NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink activeClassName='is-active' className="nav-link" to="/service/">Our services</NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink activeClassName='is-active' className="nav-link" to="/contact/">Contact us</NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Header
