import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import onMarkerting from '../images/onMarkerting.jpg'
import ofMarketing from '../images/ofMarketing.jpg'
import webDev from '../images/webDev.jpg'
import designBranding from '../images/design.jpg'
import internalBranding from '../images/internalBranding.jpg'
import mediaProduction from '../images/mediaProduction.jpg'
import motion from '../images/bg6.jpg'
import consultation from '../images/bg16.jpg'
import bulksms from '../images/bg17.jpg'
import WOW from "wowjs";


class Service extends Component {
    componentDidMount() {
        new WOW.WOW().init();
        window.scrollTo(0, 0)
      }
    render() {
        return (
            <div>
                <div className="service_page">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="content">
                                    <h1>Our services</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="title_heading">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="content">
                                    <h1>Our services</h1>
                                    <h2>We offer top-notch services based on countless years of experience in the industry.</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="service_one_container">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="im_left">
                                    <img src={onMarkerting} alt="left_im" className="img-fluid"/>
                                </div>
                            </div>
                            <div className="col">
                                <div className="right_content w3-card wow zoomIn" data-wow-delay="0.2s">
                                    <h2 style={{fontWeight: 'bold'}}>01. Online Marketing</h2>
                                    <p>It’s time to understand your business and create a strategy to accomplish your brand objectives with analytics expertise and  an intelligent team to build marketing strategies.</p>
                                    <span>- SOCIAL MEDIA MANAGEMENT (All Platforms)</span>
                                    <span>- SEO</span>
                                    <span>- MEDIA BUYING ( ONLINE ADS )</span>
                                    <span>- ONLINE CAMPAIGNS</span>
                                    <span>- MARKETING PLANS AND STRATEGY</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="service_two_container">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="service_two w3-card wow zoomIn" data-wow-delay="0.2s">
                                    <h2>02. Offline Marketing</h2>
                                    <p>Knowing “What” you are trying to achieve in your business is the first step to be able to decide “How” you are going to reach your goals through Strategy and Planning. Whether your business is small or large, we can help you present and market  your services\ products. with very catchy billboards that contain smart concepts and creative solutions for you and your customers.</p>
                                    <span>- MARKETING PLAN</span>
                                    <span>- MARKETING STRATEGY</span>
                                    <span>- MARKETING CONSULTATION</span>
                                    <span>- SMS MARKETING</span>
                                    <span>- OFFLINE CAMPAIGNS</span>
                                </div>
                                
                            </div>
                            <div className="col">
                                <div className="service_two_im">
                                    <img src={ofMarketing} alt="left_im" className="img-fluid"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="service_three_container">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="im_left">
                                    <img src={webDev} alt="left_im" className="img-fluid"/>
                                </div>
                            </div>
                            <div className="col">
                                <div className="right_content w3-card wow zoomIn" data-wow-delay="0.2s">
                                    <h2>03. Web Development and Mobile Application</h2>
                                    <p>Keep in touch with your customers and get into their pocket by letting us develop your mobile app and website that enables better your communication with your customers and enhances the reachability of your product</p>
                                    <span>- WEBSITE (E-COMMERCE - INFORMATIVE)</span>
                                    <span>- MOBILE APPLICATION (ANDROID - IOS)</span>
                                    <span>- U I / U X design</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="service_four_container">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="service_four w3-card wow zoomIn" data-wow-delay="0.2s">
                                    <h2>04. Design and Branding</h2>
                                    <p>Creating memorable icon in your customers’ brain is our goal. We offer you a professional branding for your business that ensures positioning it in its right place in the market, increases business value, and generating new customers. All of this done by our professional Artwork team and graphic designers.</p>
                                    <span>- SOCIAL MEDIA DESIGNS</span>
                                    <span>- PRINTING DESIGNS</span>
                                    <span>- CORPORATE IDENTITY</span>
                                    <span>- COMPANY PROFILE</span>

                                </div>
                                
                            </div>
                            <div className="col">
                                <div className="service_four_im">
                                    <img src={designBranding} alt="left_im" className="img-fluid"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="service_five_container">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="im_left">
                                    <img src={internalBranding} alt="left_im" className="img-fluid"/>
                                </div>
                            </div>
                            <div className="col">
                                <div className="right_content w3-card wow zoomIn" data-wow-delay="0.2s">
                                    <h2>05. Internal Branding</h2>
                                    <p>To live the brand, and guide your employees to swimming and building a brand inside the ocean of the market and act accordingly. </p>
                                    <span>- VINYL</span>
                                    <span>- 3D VINYL</span>
                                    <span>- SEE THROUGH</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="service_six_container">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="service_four w3-card wow zoomIn" data-wow-delay="0.2s">
                                    <h2>06. Media Production</h2>
                                    <p>Your business message should go with its simplest way, we know this way and offer you a whole media production service starting from the campaign concept and ideas till we deliver a final visual product that tells a story matching your business objective.</p>
                                    <span>- TV ADS</span>
                                    <span>- RADIO ADS</span>
                                    <span>- VIDEOGRAPHY</span>
                                    <span>- VIDEO MONTAGE</span>
                                    <span>- PHOTOGRAPHY</span>
                                    <span>- PHOTO EDITING</span>
                                </div>
                                
                            </div>
                            <div className="col">
                                <div className="service_four_im">
                                    <img src={mediaProduction} alt="left_im" className="img-fluid"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="service_five_container">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="im_left">
                                    <img src={motion} alt="left_im" className="img-fluid"/>
                                </div>
                            </div>
                            <div className="col">
                                <div className="right_content w3-card wow zoomIn" data-wow-delay="0.2s">
                                    <h2>07. MOTION GRAPHIC VIDEOS</h2>
                                    <p>The high production value for your production is our goal. We are specialists with high-quality servicet hat will gladly help our partners to keep up with these fast-moving tech-savvy.</p>
                                    <span>- 3D ANIMATION VIDEO</span>
                                    <span>- 2D ANIMATION VIDEO</span>
                                    <span>- INFOGRAPHIC VIDEO</span>
                                    <span>- VIDEO MONTAGE</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="service_six_container">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="service_four w3-card wow zoomIn" data-wow-delay="0.2s">
                                    <h2>08. Marketing Consultation</h2>
                                    <p>In this service, We share with you our knowledge and experience in consumer behaviour and marketing process.</p>
                                </div>
                                
                            </div>
                            <div className="col">
                                <div className="service_four_im">
                                    <img src={consultation} alt="left_im" className="img-fluid"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="service_five_container">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="im_left">
                                    <img src={bulksms} alt="left_im" className="img-fluid"/>
                                </div>
                            </div>
                            <div className="col">
                                <div className="right_content w3-card wow zoomIn" data-wow-delay="0.2s">
                                    <h2>09. SMS Marketing</h2>
                                    <p>A bulk of SMS Campaigns.. If you want to see any returns on your investment you have to let your audience land anywhere you want in everywhere.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="service_quote">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="content">
                                    <h1>Looking to improve your Business</h1>
                                    <div className="go_contact">
                                        <Link to="/contact/" className="go_to_contact w3-btn">Contact us</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        )
    }
}

export default Service
