import React, { Component } from 'react'

class HomeSection2 extends Component {
    render() {
        return (
            <div>
                <div className="section_two_compo">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="content">
                                    <h1>Create experiences and solve business problems</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default HomeSection2
