import React, { Component } from 'react'
import { Link } from 'react-router-dom'
// import { Link } from 'react-router-dom'

class Footer extends Component {
    render() {
        return (
            <div>
                <div className="footer_container">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="content">
                                    <div className="social_network">
                                        <div className="facebook">
                                            <a href="https://web.facebook.com/Adventure247-Tv-103182051279196/" target="blank"><i className="fa fa-facebook"></i></a>
                                        </div>
                                        <div className="instagram">
                                            <a href="https://www.instagram.com/adventure247tv/" target="blank"><i className="fa fa-instagram"></i></a>
                                        </div>
                                        <div className="youtube">
                                            <a href="https://www.youtube.com/channel/UCyKswVhPNMwhHRtr7t5_xAg"><i className="fa fa-youtube"></i></a>
                                        </div>
                                    </div>
                                    <div className="quick_link">
                                        <h1>Quick links</h1>
                                        <Link className="nav-link" to="/">Home</Link>
                                        <Link className="nav-link" to="/about/">about-us</Link>
                                        <Link className="nav-link" to="/service/">Our-services</Link>
                                        <Link className="nav-link" to="/contact/">Contact-us</Link>
                                    </div>
                                    <hr/>
                                    <p>Copyright <i className="fa fa-copyright"></i> {(new Date().getFullYear())} All right reserved. Adventure247</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer
