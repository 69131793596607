import React, { Component } from 'react'
import onMarkerting from '../images/onMarkerting.jpg'
import mediaProduction from '../images/mediaProduction.jpg'
import { Link } from 'react-router-dom'
import WOW from "wowjs";

class ServiceComponent extends Component {
    componentDidMount() {
        new WOW.WOW().init();
        window.scrollTo(0, 0)
      }
    render() {
        return (
            <div>
                <div className="service_compo">
                    <div className="container">
                        <h1 className="mb-4">Offered Services</h1>
                        
                        <div className="row my-2">
                            <div className="col-md-6">
                                <div className="content w3-card wow slideInLeft" data-wow-delay="0.2s">
                                    <div className="im_div">
                                        <img src={onMarkerting} alt="tv" className="img-fluid"/>
                                    </div>
                                    <h2>Online Marketing</h2>
                                    <p>It’s time to understand your business and create a strategy to accomplish your brand objectives with analytics expertise and  an intelligent team to build marketing strategies.</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="content w3-card wow slideInRight" data-wow-delay="0.3s">
                                    <div className="im_div">
                                        <img src={mediaProduction} alt="tv" className="img-fluid"/>
                                    </div>
                                    <h2>Media Production</h2>
                                    <p>Your business message should go with its simplest way, we know this way and offer you a whole media production service starting from the campaign concept and ideas till we deliver...</p>
                                </div>
                            </div>
                        </div>

                        <div className="link_to_service">
                            <Link to="/service/" className="go_to_serevice w3-btn">More offer</Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ServiceComponent
