import React from 'react';
import './App.scss';
import './w3.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Home from './pages/Home';
import About from './pages/About';
import Service from './pages/Service';
import Error from './pages/Error';
import Header from './components/Header';
import Contact from './pages/Contact';
import Footer from './components/Footer';
function App() {
  return (
    <div className="App">
     <Router>
        <Header/>
        <Switch>
          <Route path exact = '' component = {Home}/>
          <Route path = '/about/' component = {About}/>
          <Route path = '/service/' component = {Service}/>
          <Route path = '/contact/' component = {Contact}/>
          <Route component = {Error}/>
        </Switch>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
