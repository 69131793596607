import React, { Component } from 'react'
import Animate from 'animate.css-react'
import WOW from "wowjs";
class HomeSection1 extends Component {
    componentDidMount() {
        new WOW.WOW().init();
        window.scrollTo(0, 0)
      }
    render() {
        return (
            <div>
                <div className="section_one_compo">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <Animate
                                    appear="slideInLeft"
                                    durationAppear={3000}
                                    >
                                    <div className="content">
                                        <h1>Adventure 24/7 is a Tanzanian based Marketing Agency and Content production house.</h1>
                                    </div>
                                </Animate>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default HomeSection1
